import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router} from '@angular/router';
// @ts-ignore
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
// @ts-ignore
import {VexPageLayoutComponent} from '@vex/components/vex-page-layout/vex-page-layout.component';
// @ts-ignore
import {VexSecondaryToolbarComponent} from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';
// @ts-ignore
import {VexBreadcrumbsComponent} from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ErrorStateMatcher, MatOptionModule, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {AsyncPipe, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {StorageService} from '../../shared/services/storage.service';
import {AuthService} from '../../shared/services/auth.service';
import {GlobalService} from '../../shared/services/global.service';
import {UserService} from '../../shared/services/user.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AlertService} from '../../shared/services/alert.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {HeaderComponent} from "../../landing/header/header.component";
import {FooterComponent} from "../../landing/footer/footer.component";
import { NavigationLoaderService } from '../../core/navigation/navigation-loader.service';

@Component({
  selector: 'vex-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    VexPageLayoutComponent,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    MatIconModule,
    VexBreadcrumbsComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgIf,
    ReactiveFormsModule,
    NgFor,
    MatSlideToggleModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    AsyncPipe,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ],
  providers: [
    GlobalService,
    AuthService,
    UserService,
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  signInForm: FormGroup = new FormGroup({});
  inputType: any = 'password';
  visible: any = false;
  submitted: any = false;
  isBusy: any = false;
  formType: string = 'login';
  pageExtras: {} = {
    'buttonType': 'search',
    'imageUrl': '',
    'profileImage': ''
  }

  constructor(private router: Router,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private authService: AuthService,
              private globalService: GlobalService,
              private navigationService: NavigationLoaderService,
              private alertService: AlertService
  ) {
    this.checkLogin();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.loginForm = this.fb.group({
      'username': ['', [Validators.required, Validators.minLength(6)]],
      'password': ['', [Validators.required, Validators.minLength(6)]]
    });
    this.signInForm = this.fb.group({
      'name': ['', Validators.required],
      'email': ['', Validators.required],
      'contact': ['', Validators.required],
      'organisation_name': ['', Validators.required],
      'username': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  checkLogin(): void {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf(): void {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type,
        'organisation_id': data.organisation_id
      });
      this.router.navigateByUrl('/');
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }

  login(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submitted) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('/home');
      } else {
        let params: {} = {
          'username': (this.loginForm.getRawValue() && this.loginForm.getRawValue().username) ? this.loginForm.getRawValue().username : '',
          'password': (this.loginForm.getRawValue() && this.loginForm.getRawValue().password) ? this.loginForm.getRawValue().password : '',
        };
        this.authService.login(params).subscribe(data => {
          if (data && data.id) {
            this.globalService.setAccessToken(data?.token);
            this.globalService.setSelf({
              'name': data?.name,
              'email': data?.email,
              'contact': data?.contact,
              'user_id': data?.id,
              'username': data?.username,
              'user_type': data?.user_type,
              'image': data?.image
            });
            this.navigationService.getSelfDetails();
            const checkout = localStorage.getItem('checkout');
            if (checkout) {
              this.router.navigateByUrl(checkout);
              this.loginForm.reset();
              localStorage.removeItem('checkout');
            } else {
              this.loginForm.reset();
              this.router.navigateByUrl('/home');
            }
          }
        }, error => {
          this.alertService.showErrors(error.error.errorDetails);
          this.isBusy = false;
          this.submitted = false;
        });
      }
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData(): void {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  loginFormVisible(): void {
    this.formType = 'login';
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
