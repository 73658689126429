import {Injectable} from '@angular/core';

@Injectable()
export class Helper {
  static capitalize(string: any) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static capitalizeFirstWord(string: any) {
    let splitStr = string.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  static removeObject(arr, attr, value) {
    let i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  static mapTwoArrays(primaryArr, secondaryArr, primaryArrkey, secondaryArrKey, statusKey) {
    if (primaryArr && primaryArr.length > 0) {
      primaryArr.forEach((item: any) => {
        item[statusKey] = false;
        if (secondaryArr && secondaryArr.length > 0) {
          secondaryArr.forEach((dt: any) => {
            if (dt && dt[secondaryArrKey] == item[primaryArrkey]) {
              item[statusKey] = true;
            }
          });
        }
      });
      return primaryArr;
    } else {
      return [];
    }
  }

  static updateArray(primaryArr, changedArr, id) {
    if (primaryArr && primaryArr.length > 0) {
      primaryArr.forEach((items: any, key: any) => {
        if (items.id === id) {
          for (const obj in changedArr) {
            primaryArr[key][obj] = changedArr[obj];
          }
        }
      });
    }
    return primaryArr;
  }

  static formatDate(date: any): any {
    let d: any = new Date(date),
      month: any = '' + (d.getMonth() + 1),
      day: any = '' + d.getDate(),
      year: any = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  static uniqueArray(array: any, key: any): any {
    const arr: Set<any> = new Set();
    return array.filter((item: { [x: string]: any; }) => {
      const value = item[key];
      if (arr.has(value)) {
        return false;
      } else {
        arr.add(value);
        return true;
      }
    });
  }

  static addDaysWithDate(date, days) {
    let currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + days);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  static removeDuplicates(arr, key) {
    const seen = new Set();
    const uniqueArr = [];

    arr.forEach(item => {
      if (!seen.has(item[key])) {
        seen.add(item[key]);
        uniqueArr.push(item);
      }
    });
    return uniqueArr;
  }

  static filterArray(array, conditions) {
    return array.filter(item => {
      return Object.keys(conditions).every(key => {
        return item[key] === conditions[key];
      });
    });
  }
  static findCommonElements(arr1, arr2) {
    const set = new Set(arr1);
    return arr2.some(element => set.has(element));
  }
  static workingDaysCheck(workingDays: any) {
    return workingDays && !Object.values(workingDays).every(day => day === 'WEEKLY_OFF');
  }

}
