import {Injectable} from '@angular/core';

@Injectable()
export class PermissionConstant {
  static ALL = 'ALL';

  // Dashboard
  static DASHBOARD = 'DASHBOARDS_DASHBOARDS'
  static HOME = 'DASHBOARDS_HOMES';

  // General
  static GENERAL = 'GENERALS_GENERALS';
  static ADMIN = 'GENERALS_ADMINS';
  static ADD_ADMIN = 'GENERALS_ADD_ADMINS';
  static LIST_ADMIN = 'GENERALS_LIST_ADMINS';
  static CENTER = 'GENERALS_CENTERS';
  static ADD_CENTER = 'GENERALS_ADD_CENTERS';
  static LIST_CENTER = 'GENERALS_LIST_CENTERS';
  static SESSION = 'GENERALS_SESSIONS';
  static ADD_SESSION = 'GENERALS_ADD_SESSIONS';
  static LIST_SESSION = 'GENERALS_LIST_SESSIONS';
  static GENERAL_REPORT = 'GENERALS_REPORTS';

  // Employee
  static EMPLOYEE = 'EMPLOYEES_EMPLOYEE';
  static DEPARTMENT = 'EMPLOYEES_DEPARTMENTS';
  static DESIGNATION = 'EMPLOYEES_DESIGNATIONS';
  static EMPLOYEES = 'EMPLOYEES_EMPLOYEES';
  static ADD_EMPLOYEES = 'EMPLOYEES_ADD_EMPLOYEES';
  static LIST_EMPLOYEES = 'EMPLOYEES_LIST_EMPLOYEES';
  static ATTENDANCE_EMPLOYEES = 'EMPLOYEES_ATTENDANCE_EMPLOYEES';
  static PAYROLL_EMPLOYEES = 'EMPLOYEES_PAYROLL_EMPLOYEES';
  static PERMISSION = 'EMPLOYEES_PERMISSIONS';
  static EMPLOYEE_REPORT = 'EMPLOYEES_REPORTS';

  // Module
  static MODULE = 'MODULES_MODULES';
  static PROGRAM = 'MODULES_PROGRAMS';
  static COURSE = 'MODULES_COURSES';
  static BRANCH = 'MODULES_BRANCHES';
  static MAPPING = 'MODULES_MAPPINGS';
  static SUBJECT = 'MODULES_SUBJECTS';
  static MODULE_REPORT = 'MODULES_REPORTS';

  // Inventory
  static INVENTORY = 'INVENTORIES_INVENTORIES';
  static INVENTORY_CATEGORY = 'INVENTORIES_CATEGORIES';
  static INVENTORY_LIST = 'INVENTORIES_LISTS';
  static INVENTORY_TRANSACTION = 'INVENTORIES_TRANSACTIONS';
  static INVENTORY_REPORT = 'INVENTORIES_REPORTS';

  // Book
  static BOOK = 'BOOKS_BOOKS';
  static BOOK_CATEGORY = 'BOOKS_CATEGORIES';
  static BOOK_LIST = 'BOOKS_LISTS';
  static BOOK_TRANSACTION = 'BOOKS_TRANSACTIONS';
  static BOOK_REPORT = 'BOOKS_REPORTS';

  // Library
  static LIBRARY = 'LIBRARIES_LIBRARIES';
  static LIBRARY_BORROWING = 'LIBRARIES_BORROWINGS';
  static LIBRARY_TRANSACTION = 'LIBRARIES_TRANSACTIONS';
  static LIBRARY_REPORT = 'LIBRARIES_REPORTS';

  // Student
  static STUDENT = 'STUDENTS_STUDENTS';
  static STUDENT_ADD = 'STUDENTS_ADD_STUDENTS';
  static STUDENT_LIST = 'STUDENTS_LIST_STUDENTS';
  static STUDENT_VERIFY = 'STUDENTS_VERIFY_STUDENTS';
  static STUDENT_CENTER = 'STUDENTS_CENTER';
  static STUDENT_VERIFY_CENTER = 'STUDENTS_VERIFY_CENTER_STUDENTS';
  static STUDENT_TRANSACTION = 'STUDENTS_TRANSACTION_STUDENTS';
  static STUDENT_ATTENDANCE = 'STUDENTS_ATTENDANCE_STUDENTS';
  static STUDENT_ENROLLMENT = 'STUDENTS_ENROLLMENT_STUDENTS';
  static FINAL_STUDENT_LIST = 'STUDENTS_FINAL_STUDENT_LIST';
  static FINALISED_STUDENT_LIST = 'STUDENTS_FINALISED_STUDENT_LIST';
  static STUDENT_REPORT = 'STUDENTS_REPORT_STUDENTS';

  // Account
  static ACCOUNT_ACCOUNT = 'ACCOUNTS_ACCOUNTS';
  static ACCOUNT_RECEIPT = 'ACCOUNTS_RECEIPTS';
  static ACCOUNT_RECEIPT_TRANSACTION = 'ACCOUNTS_RECEIPT_TRANSACTIONS';
  static ACCOUNT_WALLET_TRANSACTION = 'ACCOUNTS_WALLET_TRANSACTIONS';
  static ACCOUNT_REPORT = 'ACCOUNTS_REPORTS';

  // Exams
  static EXAMS = 'EXAMS_EXAMS';
  static INTERNAL_EXAMS = 'EXAMS_INTERNAL_EXAMS';
  static INTERNAL_EXAM_SESSION = 'EXAMS_INTERNAL_EXAM_SESSION';
  static INTERNAL_EXAM_QUESTIONS = 'EXAMS_INTERNAL_EXAM_QUESTIONS';
  static INTERNAL_EXAM_SCHEDULER = 'EXAMS_INTERNAL_EXAM_SCHEDULER';
  static INTERNAL_EXAM_HALLS = 'EXAMS_INTERNAL_EXAM_HALLS';
  static INTERNAL_EXAM_RESULT = 'EXAMS_INTERNAL_EXAM_RESULT';
  static INTERNAL_EXAM_HALLS_ASSIGN_STUDENTS = 'EXAMS_INTERNAL_EXAM_HALLS_ASSIGN_STUDENTS';
  static INTERNAL_EXAM_SCHEDULER_FETCH_QUESTIONS = 'EXAMS_INTERNAL_EXAM_SCHEDULER_FETCH_QUESTIONS';
  static INTERNAL_EXAM_RESULT_VIEW = 'EXAMS_INTERNAL_EXAM_RESULT_VIEW';


  static FINAL_EXAMS = 'EXAMS_FINAL_EXAMS';
  static FINAL_EXAM_SESSION = 'EXAMS_FINAL_EXAM_SESSION';
  static FINAL_EXAM_QUESTIONS = 'EXAMS_FINAL_EXAM_QUESTIONS';
  static FINAL_EXAM_ELIGIBLE_STUDENTS = 'EXAMS_FINAL_EXAM_ELIGIBLE_STUDENTS';
  static FINAL_EXAM_SCHEDULER = 'EXAMS_FINAL_EXAM_SCHEDULER';
  static FINAL_EXAM_HALLS = 'EXAMS_FINAL_EXAM_HALLS';
  static FINAL_EXAM_RESULT = 'EXAMS_FINAL_EXAM_RESULT';
  static FINAL_EXAM_HALLS_ASSIGN_STUDENTS = 'EXAMS_FINAL_EXAM_HALLS_ASSIGN_STUDENTS';
  static FINAL_EXAM_SCHEDULER_FETCH_QUESTIONS = 'EXAMS_FINAL_EXAM_SCHEDULER_FETCH_QUESTIONS';
  static FINAL_EXAM_RESULT_VIEW = 'EXAMS_FINAL_EXAM_RESULT_VIEW';

  // Docs
  static DOCS = 'DOCS_DOCS';
  static DOCS_MARKSHEET = 'DOCS_MARKSHEETS';
  static DOCS_TRANSCRIPT = 'DOCS_TRANSCRIPTS';
  static DOCS_ORIGINAL_DEGREE = 'DOCS_ORIGINAL_DEGREES';


  // Utilities
  static UTILITIES = 'UTILITIES_UTILITIES';
  static UTILITIES_HOSTEL = 'UTILITIES_HOSTELS';
  static UTILITIES_VEHICLE = 'UTILITIES_VEHICLES';
  static UTILITIES_HOLIDAY = 'UTILITIES_HOLIDAYS';
  static UTILITIES_REPORT = 'UTILITIES_REPORTS';

  // Alumni
  static ALUMNI = 'ALUMNIS_ALUMNIS';
  static ALUMNI_LIST = 'ALUMNIS_LIST';

  // Support Ticket
  static SUPPORT_TICKET = 'SUPPORTS_TICKETS_SUPPORT_TICKETS';
  static STUDENT_SUPPORT_TICKET = 'SUPPORTS_TICKETS_STUDENT_TICKETS';
  static CENTER_SUPPORT_TICKET = 'SUPPORTS_TICKETS_CENTER_TICKETS';
  static SUPPORT_TICKET_REPORT = 'SUPPORTS_TICKETS_REPORTS';

  // Notification
  static NOTIFICATION = 'NOTIFICATIONS_NOTIFICATIONS';
  static STUDENT_NOTIFICATION = 'NOTIFICATIONS_STUDENT_NOTIFICATIONS';
  static CENTER_NOTIFICATION = 'NOTIFICATIONS_CENTER_NOTIFICATIONS';
  static NOTIFICATION_REPORT = 'NOTIFICATIONS_REPORTS';
}
