import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {AlertService} from './alert.service';
import {Router} from '@angular/router';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  private profileImageSubject = new Subject<string>();
  profileImage$ = this.profileImageSubject.asObservable();

  constructor(public http: HttpClient, public alertService: AlertService, private router: Router, public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  login(data: any): Observable<any> {
    return this.postRequest(AppUrl.LOGIN, data);
  }

  self(params = {}): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.SELF, params);
  }

  update(data: any): Observable<any> {
    return this.putRequest(AppUrl.UPDATE_SELF(), data);
  }

  logout(): Observable<any> {
    return this.getRequest(AppUrl.LOGOUT());
  }

  updateProfile(data: any) {
    return this.putRequest(AppUrl.UPDATE_PROFILE_IMAGE(), data);
  }

  updatePassword(data: any) {
    return this.putRequest(AppUrl.UPDATE_PASSWORD(), data);
  }

  updateProfileImage(newImageUrl: string) {
    this.profileImageSubject.next(newImageUrl);
  }

}
