import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  add(data: {}): Observable<any> {
    return this.postRequest(AppUrl.EMPLOYEE(), data);
  }

  get(data: {}): Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE(), data);
  }

  detail(uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE(uniqueId));
  }

  delete(uniqueId: any): Observable<any> {
    return this.deleteRequest(AppUrl.EMPLOYEE(uniqueId));
  }

  update(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE(uniqueId), data);
  }

  status(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_STATUS(uniqueId), data);
  }

  count(): Observable<any> {
    return this.getRequest(AppUrl.EMPLOYEE_COUNT());
  }

  changePassword(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_CHANGE_PASSWORD(uniqueId), data);
  }

  profileImage(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PROFILE_IMAGE(uniqueId), data);
  }

  govtPhoto(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_GOVT_PHOTO(uniqueId), data);
  }

  panImage(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PAN_IMAGE(uniqueId), data);
  }

  tenthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_TENTH_CERTIFICATE(uniqueId), data);
  }

  twelthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_TWELTH_CERTIFICATE(uniqueId), data);
  }

  graduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_GRADUATION_CERTIFICATE(uniqueId), data);
  }

  postGraduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_POST_GRADUATION_CERTIFICATE(uniqueId), data);
  }

  otherQualificationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_OTHER_QUALIFICATION_CERTIFICATE(uniqueId), data);
  }

  highestQualificationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_HIGHEST_QUALIFICATION_CERTIFICATE(uniqueId), data);
  }

  addPersonalInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_PERSONAL_INFO(uniqueId), data);
  }

  addBankInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_BANK_INFO(uniqueId), data);
  }

  addAllowanceInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_ALLOWANCE_INFO(uniqueId), data);
  }

  addOtherInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_OTHER_INFO(uniqueId), data);
  }

  addSalaryInfo(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.EMPLOYEE_SALARY_INFO(uniqueId), data);
  }

  getPayroll(uniqueId: any): Observable<any> {
    return this.getRequestWithoutErrorMessage(AppUrl.EMPLOYEE_PAYROLL(uniqueId));
  }
  getEmployeePayroll(data:{}): Observable<any> {
    return this.getRequest(AppUrl.ALL_EMPLOYEE_PAYROLL(), data);
  }
  downloadEmployeePayroll(data:{}): Observable<any> {
    return this.getRequest(AppUrl.DOWNLOAD_EMPLOYEE_PAYROLL(), data);
  }

  getHod():Observable<any>{
    return this.getRequestWithoutErrorMessage(AppUrl.EMPLOYEE_HOD());
  }

  assignHod(data: any, uniqueId: any):Observable<any>{
    return this.putRequest(AppUrl.EMPLOYEE_ASSIGN_HOD(uniqueId), data);
  }

  addEvaluation(data: any, uniqueId: any): Observable<any>{
    return this.putRequest(AppUrl.EMPLOYEE_EVALUATION(uniqueId), data);
  }
  getAttendance(data: {}): Observable<any> {
    return this.getRequest(AppUrl.VIEW_EMPLOYEE_ATTANDENCE(), data);
  }
}
