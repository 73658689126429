import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../layouts/components/dashboard/home.module').then(m => m.HomeModule),
  },
  {
    path: 'general',
    loadChildren: () => import('../../layouts/components/general/general.module').then(m => m.GeneralModule),
  },
  {
    path: 'employees',
    loadChildren: () => import('../../layouts/components/employees/employee.module').then(m => m.EmployeeModule),
  },
  {
    path: 'modules',
    loadChildren: () => import('../../layouts/components/module/module.module').then(m => m.ModuleModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('../../layouts/components/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'book',
    loadChildren: () => import('../../layouts/components/book/book.module').then(m => m.BookModule)
  },
  {
    path: 'library',
    loadChildren: () => import('../../layouts/components/library/library.module').then(m => m.LibraryModule)
  },
  {
    path: 'student',
    loadChildren: () => import('../../layouts/components/student/student.module').then(m => m.StudentModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../layouts/components/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('../../layouts/components/exams/exams.module').then(m => m.ExamsModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('../../layouts/components/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'utilities',
    loadChildren: () => import('../../layouts/components/utilities/utility.module').then(m => m.UtilityModule)
  },
  {
    path: 'alumni',
    loadChildren: () => import('../../layouts/components/alumni/alumni.module').then(m => m.AlumniModule)
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('../../layouts/components/support-ticket/support-ticket.module').then(m => m.SupportTicketModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('../../layouts/components/notification/notification.module').then(m => m.NotificationModule)
  },
];

export const landing: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/pages-list/Landing/Landing.module').then(m => m.LandingModule)
  }
];
