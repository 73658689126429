<div class="flex flex-col min-h-screen" style="background-image: linear-gradient(to right, #BBE9FF, #FFC7ED);">
  <app-header></app-header>
  <div class="flex-1 flex items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
    <div class="max-w-4xl w-full flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
      <div class="hidden md:block w-full md:w-1/2 p-8 bg-gradient-to-r from-purple-100 to-purple-200 relative">
        <img src="assets/images/register-org/login-auth.svg" alt="Login Image" class="w-full h-full object-contain">
        <div class="absolute top-0 left-0 p-4">
          <img class="w-16" src="assets/img/logo/images.png" alt="logo" (click)="goTo('/')"/>
        </div>
      </div>
      <div class="w-full md:w-1/2 flex flex-col">
        <div
          class="p-8 pb-0 flex flex-col items-center justify-center bg-white rounded-t-lg md:rounded-t-none md:rounded-l-lg">
          <div
            class="rounded-full mb-2 h-16 w-16 flex items-center justify-center text-primary-600 bg-primary-600/10" *ngIf="pageExtras['buttonType'] === 'login'">
            <img [src]="pageExtras['profileImage']" class="avatar-image w-full h-full rounded-full shadow-1px-light" alt="Avatar"/>
          </div>
          <h2 class="text-3xl font-semibold mb-2" [style.font-family]="'var(--vex-font)'">Welcome Back!</h2>
          <p class="text-lg text-gray-600" [style.font-family]="'var(--vex-font)'">Bringing Ambition to Life.</p>
          <img *ngIf="pageExtras['imageUrl']" [src]="pageExtras['imageUrl']" alt="logo" class="logo-icon">
        </div>
        <form [formGroup]="loginForm" class="p-8 flex flex-col gap-6 bg-white">
          <div class="flex flex-col gap-4">
            <mat-form-field appearance="outline" [hideRequiredMarker]="true">
              <mat-label>Username</mat-label>
              <input matInput placeholder="Username" formControlName="username" required  maxlength="12" [style.font-family]="'var(--vex-font)'">
              <small class="text-red-500" *ngIf="submitted && loginForm.get('username').hasError('required')">
                Username is required
              </small>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('username')?.errors?.minlength">
                Username must be at least {{loginForm.get('username')?.errors?.minlength?.requiredLength}} characters long
              </small>
            </mat-form-field>
            <mat-form-field appearance="outline" [hideRequiredMarker]="true">
              <mat-label>Password</mat-label>
              <input [type]="inputType" formControlName="password" matInput required [style.font-family]="'var(--vex-font)'">
              <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility"
                      type="button" [style.font-family]="'var(--vex-font)'">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility" [style.font-family]="'var(--vex-font)'"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off" [style.font-family]="'var(--vex-font)'"></mat-icon>
              </button>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('password').hasError('required')">
                Password is required.
              </small>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('password')?.errors?.minlength">
                 Password must be at least {{loginForm.get('password')?.errors?.minlength?.requiredLength}} characters long.
              </small>
            </mat-form-field>
          </div>
          <div class="flex items-center justify-between">
            <mat-checkbox color="primary" [style.font-family]="'var(--vex-font)'">Remember Me</mat-checkbox>
          </div>
          <button (click)="login()" color="primary" mat-raised-button class="w-full py-2 mt-4 bg-blue-500 text-white rounded-lg" [style.font-family]="'var(--vex-font)'">
            Log In
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
